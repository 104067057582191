<template>
  <div class="login-main">
    <div class="login-header">
      伊犁州托育机构质量评估标准平台
    </div>
    <div class="login-footer">
      <p>ICP备案编号：京ICP备19026963号-8 <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502049964"
          target="_blank" style="text-decoration: none;color: #fff;cursor: pointer;"><img
            src="../../assets/index/beian.png" /></a>京公网安备 11010502049964号 <span>技术支持：京学集团</span></p>
    </div>
    <div class="login-right">
      <el-popover placement="left" width="350" trigger="hover">
        <div class="right-item" style="margin-bottom: 12px;">
          <img style="width: 100%" src="@/assets/login/qr.png" alt="">
        </div>
        <div class="item item1" slot="reference" style="background: #1890FF; color: #fff;">
          <img class="hover" src="../../assets/login/icon4.png" alt="">
          <img class="no_hover" src="../../assets/login/icon4.png" alt="">
          <span>联<br />系<br />咨<br />询</span>
        </div>
      </el-popover>
    </div>
    <div class="content">
      <div class="form">
        <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane name="pwd"> -->
        <!-- <p class="p2">欢迎使用托育机构质量评估标准平台</p> -->
        <h2 class="p1">登录</h2>
        <el-form ref="pwdForm" :label-position="labelPosition" label-width="0">
          <el-form-item prop="username">
            <el-input placeholder="请输入账号" clearable v-model="username"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input show-password clearable placeholder="请输入密码" v-model="pwd"></el-input>
          </el-form-item>
          <!-- <el-form-item class="form-item mb0" v-if="!isTest">
            <el-input clearable placeholder="请输入图形验证码" v-model="code"></el-input>
            <div class="sms-btn" @click="getCode">
              <el-image class="code" :src="captcha" fit="cover"></el-image>
            </div>
          </el-form-item> -->
          <el-form-item class="form-item mb10 h30">
            <div v-if="errText" style="color: #e40011">{{ errText }}</div>
          </el-form-item>
          <el-form-item class="form-item mb5">
            <el-checkbox v-model="checked">记住账号和密码</el-checkbox>
          </el-form-item>
          <el-form-item class="form-item mb5">
            <el-button @click="pwdSubmit" @keyup.13.native="pwdSubmit" class="login-btn" type="primary"
              :loading="loading">{{ loading ? "登录中" : "登录" }}</el-button>
          </el-form-item>
          <!-- <router-link class="forget-link" to="/user/forget" tag="li">
              托育机构人员可使用<el-link :underline="false" type="primary"> 忘记密码？</el-link>
            </router-link> -->
        </el-form>
        <!-- </el-tab-pane> -->
        <!-- <el-tab-pane label="账号登录" name="pwd">
            <el-form
              ref="pwdForm"
              :label-position="labelPosition"
              label-width="0"
            >
              <el-form-item prop="username">
                <el-input
                  placeholder="请输入账号"
                  v-model="username"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  placeholder="请输入密码"
                  v-model="pwd"
                  show-pwd
                ></el-input>
              </el-form-item>
              <el-form-item class="form-item mb0">
                <el-input
                  placeholder="请输入图形验证码"
                  v-model="code"
                ></el-input>
                <div class="sms-btn">图片</div>
              </el-form-item>
              <el-form-item class="form-item mb10 h30">
                <div v-if="errText" style="color: #e40011">{{ errText }}</div>
              </el-form-item>
              <el-form-item class="form-item mb5">
                <el-checkbox v-model="checked">记住账号和密码</el-checkbox>
                <router-link
                  v-show="false"
                  class="forget-link"
                  to="/user/forget"
                  tag="li"
                >
                  <el-link :underline="false" type="primary"
                    >忘记密码？</el-link
                  >
                </router-link>
              </el-form-item>
              <el-button
                :disabled="pwdCanSubmit"
                @click="pwdSubmit"
                class="login-btn"
                type="primary"
                :loading="loading"
                >{{ loading ? "登录中" : "登录" }}</el-button
              >
            </el-form>
          </el-tab-pane> -->
        <!-- <el-tab-pane label="短信登录" name="sms">
            <el-form
              ref="smsForm"
              :label-position="labelPosition"
              label-width="0"
            >
              <el-form-item prop="phone">
                <el-input
                  placeholder="请输入手机号"
                  v-model="phone"
                  maxlength="11"
                  type="tel"
                ></el-input>
              </el-form-item>
              <el-form-item class="form-item">
                <el-input
                  placeholder="请输入图形验证码"
                  v-model="code"
                ></el-input>
                <el-button class="sms-btn"> 图片 </el-button>
              </el-form-item>
              <el-form-item class="form-item">
                <el-input placeholder="请输入验证码" v-model="sms"></el-input>
                <el-button
                  class="sms-btn"
                  :disabled="!!smsCount"
                  @click="sendSms"
                  :type="smsCount ? '' : 'success'"
                >
                  {{ smsCount ? smsCount + "s后重试" : smsBtnText }}
                </el-button>
              </el-form-item>
              <el-button
                class="login-btn"
                :disabled="smsCanSubmit"
                @click="smsSubmit"
                type="primary"
                >登录</el-button
              >
            </el-form>
          </el-tab-pane> -->
        <!-- </el-tabs> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Header } from 'element-ui';

export default {
  name: "Login",
  data() {
    return {
      activeName: "pwd",
      labelPosition: "right",
      username: "",
      phone: "",
      pwd: "",
      code: "",
      sms: "",
      smsCount: 0,
      smsBtnText: "发送验证码",
      smsTimer: null,
      errText: "",
      checked: false,
      loading: false,
      roleList: [{
        role_id: 1,
        role_name: '超级管理员'
      }, {
        role_id: 2,
        role_name: '国级评估专家'
      }, {
        role_id: 3,
        role_name: '区县评估专家'
      }, {
        role_id: 4,
        role_name: '超级管理员'
      }],
      captcha: '',
      client: '',
    };
  },
  mounted() {
    let that = this;
    document.onkeypress = function (e) {
      if (that.$route.path == '/user/login') {
        var keycode = document.all ? event.keyCode : e.which;
        if (keycode == 13) {
          that.pwdSubmit()
          return false;
        }
      }
    };
    // this.getCode();
  },
  computed: {
    isTest() {
      return false
    },
    pwdCanSubmit() {
      return !(this.username && this.pwd);
    },
    smsCanSubmit() {
      return !(this.phone && this.sms && this.code);
    },
  },
  components: {},
  methods: {
    async getCode() {
      let res = await this.$api({
        method: "get",
        url: "/api/img_code",
      });
      console.log(res);
      if (res.code == 0) {
        this.captcha = res.data.captcha
        this.client = res.data.client
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    handleClick() { },
    async pwdSubmit() {
      let data = {
        mobile: this.username,
        password: this.pwd,
        captcha: this.code,
        client: this.client,
      };
      if (this.isTest) {
        data = {
          mobile: this.username,
          code: '04837'
        };
      }
      this.loading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/auth/login",
        data: data,
      });
      if (res.code == 0) {
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("role", res.data.role_id);
        localStorage.setItem("name", res.data.name);
        localStorage.setItem("username", res.data.username);
        localStorage.setItem("is_change_pswd", res.data.is_change_pswd);
        localStorage.setItem("organization_id", res.data.organization_id);
        localStorage.setItem("organization_name", res.data.organization_name);
        localStorage.setItem("is_organ_check", res.data.is_organ_check);
        localStorage.setItem("is_bind_mobile", res.data.phone ? 1 : 0);
        if (res.data.is_change_pswd == 0) {
          this.$router.push({
            path: "/user/password"
          });
        } else {
          this.$message({
          message: '登录成功',
          type: 'success'
        });;
          this.$router.push({
            path: "/"
          });
        }
        // else if (res.data.is_organ_check == 0) {
        //   this.$router.push({
        //     path: "/user_organ/set"
        //   });
        // }
      } else {
        // this.getCode();
        // this.code = '';
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
      this.loading = false;
    },
    smsSubmit() {
      this.$refs["pwdForm"].validate((valid) => {
        return valid;
      });
    },
    sendSms() {
      this.smsCount = 3;
      this.smsTimer = setInterval(() => {
        this.smsCount--;
        if (this.smsCount == 0) {
          clearInterval(this.smsTimer);
          this.smsBtnText = "重发验证码";
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.right-item {
  display: flex;
  margin: 12px 12px 0;

  .img {
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    background: #F0F3F7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;

    img {
      width: 24px;
    }
  }

  .right-text {
    flex: auto;

    .t1 {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }

    .t2 {
      font-size: 14px;
      color: #1890FF
    }

    .t3 {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

.login-main {
  overflow: hidden;
  width: 100vw;
  // min-width: 1440px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: url("../../assets/index/bg.png") no-repeat top;
  background-size: 100vw calc(100vw / 1920 * 1280);
  height: 100vh;
  position: relative;

  .login-header {
    position: absolute;
    top: 24px;
    left: 24px;
    color: #fff;
    font-weight: bold;
    font-size: 22px;

    img {
      height: 72px;
    }
  }

  .login-footer {
    position: absolute;
    bottom: 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;

    span {
      margin: 0 10px;
    }

    img {
      transform: translateY(4px);
      margin: 0 4px 0 20px;
    }
  }

  .login-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 24px;
    width: 48px;
    cursor: pointer;

    .item {
      width: 48px;
      height: 138px;
      color: rgba(0, 0, 0, 0.45);
      background: #fff;
      border-radius: 24px;
      margin: 20px 0;
      font-size: 14px;
      line-height: 1.4;
      overflow: hidden;

      .no_hover {
        display: block;
      }

      .hover {
        display: none;
      }

      img {
        width: 24px;
        margin: 12px 12px 6px;
      }

      &:hover {
        .no_hover {
          display: none;
        }

        .hover {
          display: block;
        }
      }
    }

    .item1:hover {
      background: #40A9FF !important;
    }

    .item2:hover {
      color: #40A9FF;
    }
  }

  .content {
    width: 1240px;
    height: 100%;
    min-height: calc(704px * 0.75);
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .code {
    height: 100%;
  }

  .form {
    width: 448px;
    // height: 483px;
    background: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0px 7px 16px 0px rgba(22, 127, 204, 0.13);
    padding: 30px 40px;
    margin-right: 7%;

    .p1 {
      font-size: 32px;
      font-weight: 500;
      color: #000000;
      line-height: 45px;
      letter-spacing: 2px;
      margin-top: 12px;
      margin-bottom: 40px;
    }

    .p2 {
      font-size: 20px;
      color: #999999;
      line-height: 28px;
      letter-spacing: 1px;
      margin-top: 20px;
    }

    .login-btn {
      width: 100%;
    }

    .sms-btn {
      width: 88px;
      flex: 0 0 88px;
      margin-left: 16px;
      display: flex;
      height: 42px;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 4px;
    }

    .forget-link {
      text-align: right;
      width: 100%;
      display: block;
      cursor: pointer;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #409EFF;
      letter-spacing: 0.5px;
      font-weight: 500;
      font-size: 15px;

      .el-link {
        text-decoration: underline;
        font-size: 15px;

      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .login-main {
    background-size: calc(1920px * 0.75) calc(1280px * 0.75);
    .content {
      width: 960px;

      .form {
        width: calc(448px * 0.75);
        margin-right: 2.7%;
        padding: calc(30px * 0.75) calc(40px * 0.75);

        .p1 {
          font-size: 23px;
          margin-top: 8px;
          margin-bottom: 18px;
        }

        .p2 {
          font-size: 15px;
          margin-top: 14px;
          margin-bottom: 0;
        }
      }

      .sms-btn {
        width: 88px;
        flex: 0 0 calc(118px * 0.75);
        margin-left: calc(16px * 0.75);
        // height: 31.5px;
      }
    }
  }
}
</style>

<style lang="scss">
.login-main {
  .el-tabs__header {
    margin-bottom: 22px;
  }

  .el-tabs__item {
    padding: 0 12px;
    font-size: 18px;
    width: 88px;
    color: #666666;
    flex: 1;
  }

  .el-tabs__active-bar {
    width: 30px !important;
    height: 4px;
    background: #0083f5;
    border-radius: 2px;
    margin-left: 23px;
  }

  .el-tabs__item.is-active {
    color: #333333;
    font-weight: 500;
  }

  .el-tabs__nav {
    width: 100%;
  }

  .el-tabs__active-bar {
    display: none;
  }

  .el-tabs__nav-wrap::after {
    display: none;
  }

  .el-input__inner {
    background: #FFFFFF !important;
    border-radius: 8px !important;
    border: 1px solid #D9D9D9 !important;
    height: 40px !important;
    padding: 0 15px !important;
  }
}
</style>
